import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@molecules';
import { ChairToc, SimpleHero, Appointee } from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';
import { useActiveId } from '@hooks';

import * as styles from '../components/organisms/ChairToc/ChairToc.module.scss';

const ContentTransitionListingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { siteTitle, sharedLabels } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent,
    disableAlertBar, enLanguagePageLinks, pageInOtherLanguages,
  } = page || {};
  const {
    title, intro, listingSections,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const searchParams = setSearchParams(location);

  const idList = [];
  if(listingSections) {
    {listingSections && listingSections.map((section) => {
      const { listingItems } = section || {};
      listingItems.forEach(chair => (idList.push(`contentful-${chair.contentful_id}`)));
    })}
  }
  const activeId = useActiveId(idList);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription &&  metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme="transparent"
      settings={settings}
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
      footerBackground="white"
      heroLogo="lightblue"
    >
      <SimpleHero
       title={title}
       heroColor="lightpurple"
       intro={intro}
       noSticky={true}
      />
        {listingSections && listingSections.map((section) => {
          const { listingItems, title } = section || {};
          const itemLength = listingItems.length;

          return(
            <div className={styles.chairtoc__gutters}>
              {itemLength > 1 ? (
                <React.Fragment>
                  <ChairToc items={listingItems} activeId={activeId} title={title} />
                  <div className={styles.chairtoc__right}>
                      {listingItems && listingItems.map((chair,i) => {
                        return (
                          <Appointee {...chair} index={i} activeId={activeId} />
                        );
                      })}
                  </div>
                </React.Fragment>
                ) : (
                  <React.Fragment>
                    {listingItems && listingItems.map((chair,i) => {
                      return (
                        <Appointee {...chair} index={i} activeId={activeId} />
                      );
                    })}
                  </React.Fragment>
                )
              }
            </div>
          );
        })}
    </Layout>
  );
};

export default ContentTransitionListingTemplate;

export const contentTransitionListingPageQuery = graphql`
  query ContentTransitionListingBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentContentListing {
          title
          intro {
            raw
          }
          listingSections {
            title
            contentful_id
            listingItems {
              contentful_id
              firstName
              lastName
              title
              headshot {
                gatsbyImageData
              }
              fullBioLink {
                externalLink
                linkTitle
                internalPageLink {
                  path
                }
              }
              shortBio {
                raw
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
